import getEnv from "./helpers/get_env";

// TODO: Figure out why `import` syntax complains
//       when `require` syntax doesn't.
//
// We need the return value as the production path
// will include the computed hash.
const liveCSVPath = require("live.csv").default;

const CONFIG = {
  development: {
    CSV_URL: "http://" + window.location.host + liveCSVPath,
    GOOGLE_MAPS_KEY: "AIzaSyBb6XbTyiPx2oNyaNQFzuZN67p0lN6N9FE",
    MAP_CENTER: { lat: 41.824, lng: -71.4128 } // Providence, RI
  },
  staging: {
    CSV_URL: "https://" + window.location.host + liveCSVPath,
    GOOGLE_MAPS_KEY: "AIzaSyBb6XbTyiPx2oNyaNQFzuZN67p0lN6N9FE",
    MAP_CENTER: { lat: 41.824, lng: -71.4128 } // Providence, RI
  },
  production: {
    CSV_URL: "https://" + window.location.host + liveCSVPath,
    GOOGLE_MAPS_KEY: "AIzaSyBb6XbTyiPx2oNyaNQFzuZN67p0lN6N9FE",
    MAP_CENTER: { lat: 41.824, lng: -71.4128 } // Providence, RI
  }
};

export const { CSV_URL, GOOGLE_MAPS_KEY, MAP_CENTER } = CONFIG[getEnv()];

import React, { useState } from "react";
import Filter from "./filter";
import ListView from "./list_view";
import { IRestaurant } from "../types/restaurant";

interface IListViewWithFilterProps {
  restaurants: Array<IRestaurant>;
  focusedRestaurant?: IRestaurant;
  onItemClick: (restaurant: IRestaurant) => void;
}

export default function ListViewWithFilter({
  restaurants,
  focusedRestaurant,
  onItemClick
}: IListViewWithFilterProps) {
  const [filterString, setFilterString] = useState<string>();

  const handleFilterChange = (filter: string) => {
    setFilterString(filter);
  };

  return (
    <div className="list-view-with-filter">
      <Filter onChange={handleFilterChange} />
      <ListView
        filterValue={filterString}
        restaurants={restaurants}
        focusedRestaurant={focusedRestaurant}
        onItemClick={onItemClick}
      />
    </div>
  );
}

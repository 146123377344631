import React, { useState, useEffect, useRef } from "react";

import "./App.css";

import Map from "./components/map";
import ListViewWithFilter from "./components/list_view_with_filter";
import { IRestaurant } from "./types/restaurant";

import loadData from "./helpers/data_loader";

export default function App() {
  const mapRef = useRef<any>();

  const [restaurants, setRestaurants] = useState<Array<IRestaurant>>([]);
  const [focusedRestaurant, setFocusedRestaurant] = useState<IRestaurant>();

  useEffect(() => {
    loadData({
      onCSVLoadComplete: results => {
        if (results) {
          const massaged = results.data
            .filter(d => d.link.length) // Filter out any entries that are missing a link
            .map(d => {
              const u = { lat: parseFloat(d.lat), long: parseFloat(d.long) };
              return {
                ...d,
                ...u
              };
            });

          setRestaurants(massaged);
        }
      }
    });
  }, []);

  const handleRestaurantClick = (restaurant: IRestaurant) => {
    setFocusedRestaurant(restaurant);
    mapRef.current?.panTo({ lat: restaurant.lat, lng: restaurant.long });
    if (mapRef.current) mapRef.current.zoom = 16;
  };

  const handleGoogleApiLoaded = ({ map }: any) => {
    mapRef.current = map;
  };

  return (
    <div className="app">
      <div className="header">
        Restaurants accepting Takeout and Delivery in RI
      </div>
      <div className="content">
        <ListViewWithFilter
          restaurants={restaurants}
          focusedRestaurant={focusedRestaurant}
          onItemClick={handleRestaurantClick}
        />
        <Map
          restaurants={restaurants}
          focusedRestaurant={focusedRestaurant}
          onMarkerClick={handleRestaurantClick}
          onGoogleApiLoaded={handleGoogleApiLoaded}
        />
      </div>
    </div>
  );
}

import React from "react";
import GoogleMapReact from "google-map-react";
import { IRestaurant } from "../types/restaurant";
import { GOOGLE_MAPS_KEY, MAP_CENTER } from "../app_constants";

interface IMarkerProps {
  restaurant: IRestaurant;
  lat: number;
  lng: number;
  onClick: (restaurant: IRestaurant) => void;
}

const Marker = ({ restaurant, onClick }: IMarkerProps) => (
  <span className="map-marker" onClick={() => onClick(restaurant)}>
    <img
      src="https://upserve.com/media/cropped-upserve-logo-amp-200x200.png"
      alt={restaurant.name}
    />
  </span>
);

interface IMapProps {
  restaurants: Array<IRestaurant>;
  focusedRestaurant?: IRestaurant;
  onMarkerClick: (restaurant: IRestaurant) => void;
  onGoogleApiLoaded: (map: any) => void;
}

export default function Map({
  restaurants,
  focusedRestaurant,
  onMarkerClick,
  onGoogleApiLoaded
}: IMapProps) {
  const center = focusedRestaurant
    ? { lat: focusedRestaurant.lat, lng: focusedRestaurant.long }
    : MAP_CENTER;

  return (
    <div className="olo-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
        zoom={10}
        center={center}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
        {restaurants.map(restaurant => {
          return (
            <Marker
              key={restaurant.link + restaurant.lat + "," + restaurant.long}
              lat={restaurant.lat}
              lng={restaurant.long}
              restaurant={restaurant}
              onClick={onMarkerClick}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

import React, { useEffect, useRef } from "react";
import { IRestaurant } from "../types/restaurant";

interface IListViewProps {
  restaurants: Array<IRestaurant>;
  focusedRestaurant?: IRestaurant;
  filterValue?: string;
  onItemClick: (restaurant: IRestaurant) => void;
}

export default function ListView({
  restaurants,
  focusedRestaurant,
  onItemClick,
  filterValue
}: IListViewProps) {
  const listItemEl = useRef<any>();

  useEffect(() => {
    listItemEl.current?.scrollIntoView({ behavior: "smooth" });
  });

  const filteredRestaurants = filterValue
    ? restaurants.filter(r =>
        r.name.toLowerCase().includes(filterValue.toLowerCase())
      )
    : restaurants;

  return (
    <ul className="list-view">
      {filteredRestaurants.map(restaurant => {
        const isFocused = focusedRestaurant && focusedRestaurant === restaurant;

        return (
          <li
            key={restaurant.link + restaurant.lat + "," + restaurant.long}
            ref={isFocused ? listItemEl : undefined}
            className={`item ${isFocused ? "focused" : ""}`}
            onClick={() => onItemClick(restaurant)}
          >
            <span className="name">{restaurant.name}</span>
            <span className="address">{restaurant.address}</span>
            <span className="description">{restaurant.description}</span>
            <span className="link">
              <a href={restaurant.link}>Online Ordering</a>
            </span>
          </li>
        );
      })}
    </ul>
  );
}

import React from "react";

interface IFilterProps {
  onChange: (filterString: string) => void;
}

export default function Filter({ onChange }: IFilterProps) {
  return (
    <div className="filter">
      <input
        type="text"
        placeholder="Search"
        onChange={event => onChange(event.target.value)}
      />
    </div>
  );
}

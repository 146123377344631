import { readRemoteFile } from "react-papaparse";

import { CSV_URL } from "../app_constants";
import { ParseResults, ParseErrors } from "../types/parser";

function onComplete(results: ParseResults, file?: File): void {
  console.log("Parsing complete:", results, file);
}

function onError(error: ParseErrors, file?: File): void {
  console.log("Parsing error:", error, file);
}

export default function loadData({
  onCSVLoadComplete = onComplete,
  onCSVLoadError = onError
}) {
  const config = {
    complete: onCSVLoadComplete,
    error: onCSVLoadError,
    worker: true, // keep UI thread unblocked
    header: true // CSV file comntains a header
  };

  readRemoteFile(CSV_URL, config);
}

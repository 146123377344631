function getEnv(): "production" | "staging" | "development" {
  if (location.host === "supportrirestaurants.com") {
    return "production";
  } else if (location.host === "staging.supportrirestaurants.com") {
    return "staging";
  }
  return "development";
}

export default getEnv;
